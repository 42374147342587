import axios from 'axios';

export default class BusinessUnitService {
  static token =
  'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJFSVZFIFdTIiwiZXhwIjoxNzM4NjY3OTc1LCJuYmYiOjE3MTE0NTE5NzUsImlhdCI6MTczNzM3MTk3NSwic3Vic2lkaWFyeUlkIjoiMSIsIkNkQ29tcGFueSI6IjEiLCJDZFN1YnNpZGlhcnkiOiIxIiwidXNlcm5hbWUiOiJBUElEQjEifQ.xmODnFwGUpkeDTFDKKtAwhAVppKpLdu1FFGpiVgEAzRKXf4kRCwHaOLoYpnVAGN0cYrsdQHUwKH4AKNSU1G6BA';

  static getYearNow() {
    const today = new Date();
    if ((today.getDate() < 20 && today.getMonth() === 1) || today.getMonth() === 0) {
      return today.getFullYear() - 1;
    }
    return today.getFullYear();
  }

  static getMonthNow() {
    const today = new Date();
    let result;
    if (today.getDate() > 20) {
      result = today.getMonth() - 1;
    } else {
      result = today.getMonth() - 2;
    }
    if (result >= 0) {
      return result;
    }
    return result + 12;
  }

  static monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
  ];

  static monthNameNow = this.monthNames[this.getMonthNow()];

  public static getDataMonthNow(businessUnitName: string, ano: number) {
    return axios({
      method: 'get',
      url: `/api/operacional/consultasql/PHANTOMCALCULO?NMEMPRESA=${businessUnitName}&MES=${this.getMonthNow() + 1}&ANO=${ano}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Session-ID': `${this.token}`,
      },
    });
  }

  public static getDataFirstMonthInYear(businessUnitName: string, ano: number) {
    return axios({
      method: 'get',
      url: `/api/operacional/consultasql/PHANTOMCALCULO?NMEMPRESA=${businessUnitName}&MES=1&ANO=${ano}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Session-ID': `${this.token}`,
      },
    });
  }

  public static getDataLastMonthInYear(businessUnitName: string, ano: number) {
    return axios({
      method: 'get',
      url: `/api/operacional/consultasql/PHANTOMCALCULO?NMEMPRESA=${businessUnitName}&MES=12&ANO=${ano}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Session-ID': `${this.token}`,
      },
    });
  }

  public static async getMonthNowBusinessUnitData(businessUnitName: string, year: number) {
    const businessUnitNowMonth = (await BusinessUnitService
      .getDataMonthNow(businessUnitName, year)).data.resultadosql[0];
    return BusinessUnitService.formatValues(businessUnitNowMonth);
  }

  public static async getFirstMonthBusinessUnitData(businessUnitName: string, year: number) {
    const businessUnitFirstMonth = (await BusinessUnitService
      .getDataFirstMonthInYear(businessUnitName, year)).data.resultadosql[0];
    return businessUnitFirstMonth ? BusinessUnitService.formatValues(businessUnitFirstMonth) : { vlacao: '-' };
  }

  public static async getlastYearBusinessUnitValue(businessUnitName: string, year: number) {
    const businessUnitLastMonth = (await BusinessUnitService
      .getDataLastMonthInYear(businessUnitName, year - 1)).data.resultadosql[0];
    return businessUnitLastMonth ? BusinessUnitService.formatCurrencyToBRL(businessUnitLastMonth.vlempresa) : '-';
  }

  public static async getDataByBusinessUnitMonthAndYear(
    businessUnitName: string,
    month: number,
    year: number,
  ) {
    const response = await axios({
      method: 'get',
      url: `/api/operacional/consultasql/PHANTOMCALCULO?NMEMPRESA=${businessUnitName}&MES=${month}&ANO=${year}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Session-ID': `${this.token}`,
      },
    });
    const businessUnitData = response.data.resultadosql[0];
    return businessUnitData
      ? BusinessUnitService.formatValues(response.data.resultadosql[0])
      : {
        vlacao: '-', pcvarmes: '-', pcvarano: '-', qtacoes: '-', vlempresa: '-',
      };
  }

  public static getAllDataByMonthAndYear(month: number, year: number) {
    return axios({
      method: 'get',
      url: `/api/operacional/consultasql/PHANTOMCALCULO?MES=${month}&ANO=${year}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Session-ID': `${this.token}`,
      },
    });
  }

  public static async getAllDataByBusinessUnitAndYear(businessUnitName: string, year: number) {
    const response = await axios({
      method: 'get',
      url: `/api/operacional/consultasql/PHANTOMCALCULO?NMEMPRESA=${businessUnitName}&ANO=${year}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Session-ID': `${this.token}`,
      },
    });
    return response.data.resultadosql.sort((a: any, b: any) => a.mes - b.mes);
  }

  public static async getMonthNowBusinessUnitsData(month: number, year: number) {
    const businessUnitsNowMonth = (await BusinessUnitService
      .getAllDataByMonthAndYear(month, year)).data.resultadosql;
    return businessUnitsNowMonth;
  }

  public static async getFirstMonthBusinessUnitsData(year: number) {
    const businessUnitFirstMonth = (await BusinessUnitService
      .getAllDataByMonthAndYear(1, year)).data.resultadosql;
    return businessUnitFirstMonth;
  }

  public static async getLastMonthOfLastYearBusinessUnitsValue(year: number) {
    const businessUnitLastMonth = (await BusinessUnitService
      .getAllDataByMonthAndYear(12, year - 1)).data.resultadosql;
    return businessUnitLastMonth;
  }

  public static getMonthNameNow() {
    return this.monthNameNow;
  }

  public static getMonthName(mes: number) {
    return this.monthNames[mes - 1];
  }

  public static formatQuantity(quantity: any) {
    return String(quantity).replace(/(.)(?=(\d{3})+$)/g, '$1.');
  }

  public static formatCurrencyToBRL(value: any) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }

  public static formatValues(values: any) {
    const newValues = values;
    newValues.qtacoes = BusinessUnitService.formatQuantity(values.qtacoes);
    newValues.vlempresa = BusinessUnitService.formatCurrencyToBRL(values.vlempresa);
    newValues.vlacao = BusinessUnitService.formatCurrencyToBRL(values.vlacao);
    newValues.pcvarmes = `${values.pcvarmes}%`;
    newValues.pcvarano = `${values.pcvarano}%`;
    return newValues;
  }
}
